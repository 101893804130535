var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("DataTable", {
        attrs: {
          items: _vm.users,
          fields: [
            {
              key: "displayName",
              label: "Anzeigename",
              sorter: true,
              filter: false
            },
            {
              key: "userName",
              label: "User Name",
              sorter: true,
              filter: false
            },
            {
              key: "playroomCount",
              label: "Playrooms",
              sorter: false,
              filter: false
            },
            "createdAt",
            "lastLoginAt",
            {
              key: "actions",
              label: "Actions",
              sorter: false,
              filter: false
            }
          ],
          "table-filter-value": _vm.tableFilterValue,
          "sorter-value": _vm.sorterValue,
          loading: _vm.loading
        },
        on: {
          "update:tableFilterValue": function($event) {
            _vm.tableFilterValue = $event
          },
          "update:table-filter-value": function($event) {
            _vm.tableFilterValue = $event
          },
          "update:sorterValue": function($event) {
            _vm.sorterValue = $event
          },
          "update:sorter-value": function($event) {
            _vm.sorterValue = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "displayName",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("td", [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "c-tooltip",
                          rawName: "v-c-tooltip",
                          value: { appendToBody: true, content: item.id },
                          expression: "{ appendToBody: true, content: item.id }"
                        }
                      ]
                    },
                    [
                      _c("router-link", {
                        attrs: {
                          to: {
                            name: "AdminHostDetail",
                            params: { hostUserId: item.id }
                          }
                        },
                        domProps: { textContent: _vm._s(item.displayName) }
                      })
                    ],
                    1
                  )
                ])
              ]
            }
          },
          {
            key: "userName",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "td",
                  [
                    _c(
                      "CBadge",
                      {
                        attrs: {
                          color: item.emailConfirmed ? "secondary " : "danger"
                        }
                      },
                      [_vm._v(" " + _vm._s(item.userName) + " ")]
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "createdAt",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "td",
                  [
                    _c("FormatedTimestamp", { attrs: { date: item.createdAt } })
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "lastLoginAt",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "td",
                  [
                    _c("FormatedTimestamp", {
                      attrs: { date: item.lastLoginAt }
                    })
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "actions",
            fn: function(ref) {
              var index = ref.index
              var item = ref.item
              return [
                _c(
                  "td",
                  { staticClass: "py-2" },
                  [
                    _c(
                      "CDropdown",
                      {
                        staticClass: "m-2",
                        attrs: { color: "primary", "toggler-text": "" }
                      },
                      [
                        _c(
                          "CDropdownItem",
                          {
                            staticClass: "text-warning",
                            attrs: { "data-cy": "login-as" },
                            on: {
                              click: function($event) {
                                return _vm.loginAs(item)
                              }
                            }
                          },
                          [_vm._v(" Login ")]
                        ),
                        _c(
                          "CDropdownItem",
                          {
                            attrs: {
                              to: {
                                name: "AdminHostDetail",
                                params: { hostUserId: item.id }
                              }
                            }
                          },
                          [_vm._v(" Anzeigen ")]
                        ),
                        _c(
                          "CDropdownItem",
                          {
                            staticClass: "text-warning",
                            on: {
                              click: function($event) {
                                return _vm.openPasswordChangeModal(index)
                              }
                            }
                          },
                          [_vm._v("Password ändern")]
                        ),
                        !item.emailConfirmed
                          ? _c(
                              "CDropdownItem",
                              {
                                staticClass: "text-warning",
                                attrs: { "data-testid": "confirm-email" },
                                on: {
                                  click: function($event) {
                                    return _vm.confirmEmail(item)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "pages.admin.AdminHostIndex.confirmEmail"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._l(item.invoiceMonths, function(invoiceMonth) {
                          return _c(
                            "CDropdownItem",
                            {
                              key: invoiceMonth.dateString,
                              on: {
                                click: function($event) {
                                  return _vm.downloadInvoice(
                                    item.id,
                                    invoiceMonth.dateString
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                " Rechnung " +
                                  _vm._s(
                                    _vm.formatIsoDateTimeTz(
                                      invoiceMonth.dateString,
                                      "MMMM yyyy"
                                    )
                                  ) +
                                  " (" +
                                  _vm._s(_vm.formatCurrency(invoiceMonth.sum)) +
                                  ") (Elemente: " +
                                  _vm._s(invoiceMonth.count) +
                                  ") "
                              )
                            ]
                          )
                        }),
                        _c(
                          "CDropdownItem",
                          {
                            staticClass: "text-danger",
                            on: {
                              click: function($event) {
                                return _vm.openDeleteModal(index)
                              }
                            }
                          },
                          [_vm._v(" Löschen ")]
                        )
                      ],
                      2
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("CPagination", {
        attrs: { pages: _vm.pages, activePage: _vm.currentPage },
        on: {
          "update:activePage": function($event) {
            _vm.currentPage = $event
          },
          "update:active-page": function($event) {
            _vm.currentPage = $event
          }
        }
      }),
      _c("AdminChangePassword", {
        attrs: { passwordChangeUserName: _vm.passwordChangeUserName },
        on: {
          success: function($event) {
            return _vm.closePasswordChangeModal()
          }
        }
      }),
      _c("DeleteConfirmationModal", {
        attrs: {
          show: _vm.showDeleteModal,
          message: _vm.$t("pages.AdminOwnerIndex.deleteMsg")
        },
        on: { delete: _vm.deleteUser, close: _vm.closeDeleteModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }